document.addEventListener('DOMContentLoaded', function injectDependencies() {
    // JS modules injection
    function loadScripts() {
        var scriptsList = [];
        var scriptsSrcs = document.querySelectorAll('[data-scripts]');
        // Harvest scripts
        [].forEach.call(scriptsSrcs, function harvestScripts(scriptSrc) {
            var scripts = scriptSrc.getAttribute('data-scripts').split(', ');
            scripts.forEach(function addScriptToList(script) {
                if (scriptsList.indexOf(script) < 0) {
                    scriptsList.push(script);
                }
            });
        });
        // Load scripts
        scriptsList.forEach(function appendScripts(script) {
            var scriptTag = document.createElement('script');
            scriptTag.src = script;
            document.body.appendChild(scriptTag);
        });
    }
    function loadDependencies() {
        var depsList = [];
        var depsSrcs = document.querySelectorAll('[data-dependencies]');
        var loadedDeps = 0;
        // Harvest dependencies
        [].forEach.call(depsSrcs, function harvestDependencies(depSrc) {
            var deps = depSrc.getAttribute('data-dependencies').split(', ');
            deps.forEach(function addDependencyToList(dep) {
                if (depsList.indexOf(dep) < 0) {
                    depsList.push(dep);
                }
            });
        });
        // Load Dependencies
        if (depsList.length !== 0) {
            depsList.forEach(function appendDependencies(dep) {
                var depTag = document.createElement('script');
                depTag.src = dep;
                depTag.onload = function countLoadedDependencies() {
                    loadedDeps++;
                    if (loadedDeps === depsList.length) {
                        loadScripts();
                    }
                };
                document.body.appendChild(depTag);
            });
        } else {
            loadScripts();
        }
    }
    loadDependencies();
});
